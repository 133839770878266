import axios from "axios";
import { gettoken} from '@/utils/auth'
const http = axios.create({
  // baseURL: 'https://saas-zf.bawanli.com',
  //  baseURL: 'https://gateway.bawanli.com',
  //  baseURL: 'https://gateway.whatsbuy.com',//正式
   baseURL: 'https://gateway-test.bawanli.com',//测试地址
  // baseURL: 'https://gateway-uat.whatsbuy.com',//uat地址
  // baseURL: 'http://192.168.110.154:8098',//本地磊哥
  // https://gateway.bawanli.com
  // baseURL: 'https://tx-gateway.whatsbuy.com',
  timeout: 90000 
})
let loadingInstance;

//拦截器  -请求拦截
http.interceptors.request.use(
  config => {
    config.headers['clientId'] = 'users'
    config.headers['storeId'] = localStorage.getItem('storeID')
    config.headers['Authorization'] =gettoken()
  return config;
}, err => {
  return Promise.reject(err)
}
)

//拦截器  -响应拦截
http.interceptors.response.use(
  response => {
    const res = response.data
    loadingInstance && loadingInstance.close();
    if (res.code !== 200) {
      
      return Promise.resolve(res)
    } else {
    return Promise.resolve(res)
  }
},err=>{
  return Promise.reject(err)
});

  
export default http;
